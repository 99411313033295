* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.table_info {
  margin-top: 20px;
  overflow: auto;
}

.table_info table {
  border-collapse: collapse;
  width: 100%;
}

.table_info table thead tr {
  background-color: #4288f1;
  color: white;
}

.table_info table td,
th {
  border: 1px solid #dddddd;
  border-collapse: collapse;
  text-align: left;
  padding: 16px;
  white-space: nowrap;
}

.table_info table th {
  border: 1px solid #4288f1;
}

.table_info::-webkit-scrollbar {
  display: block;
}

.table_info table tbody tr:nth-child(odd) {
  background-color: #edf2f2;
}

.table_info table tbody tr:hover {
  background-color: #e3e9e9;
}

.table_info table td img {
  width: 80px;
  height: 55px;
  border-radius: 5px;
}

.table_info table td label {
  background-color: #d34a4a;
  color: white;
  padding: 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
}

.table_info table td .edit {
  width: 20px;
  height: 20px;
  background-color: #d34a4a;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.table_info table td .edit > * {
  font-size: 12px;
}

.table_info table td .delete {
  width: 20px;
  height: 20px;
  background-color: #d34a4a;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.table_info table td .delete > * {
  font-size: 14px;
}

.table_info table td .remove {
  width: 20px;
  height: 20px;
  background-color: #ffc107;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table_info table td .remove > * {
  font-size: 14px;
}

.table_info table td .toggle {
  width: 20px;
  height: 20px;
  background-color: #007bff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.table_info table td .toggle > * {
  font-size: 14px;
}

.table_info table td .actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
}

.dash_board::-webkit-scrollbar {
  display: none;
}

.admin {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin_1 {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.admin_1 .img1 {
  position: absolute;
  top: 20px;
  left: 30px;
}

.form {
  width: 400px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #969494;
}

.form_1 {
  width: 60%;
}

.form_11 {
  position: absolute;
  right: 0;
}

.form_12 {
  position: absolute;
  left: 0;
}

.form_1 img {
  width: 80%;
  height: 330px;
}

.form h1 {
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.input_box {
  padding-top: 14px;
  width: 100%;
  position: relative;
}

/* .input_box login{
   margin: 5px; */

.input_box input {
  color: black;
  border-radius: 6px;
  border: 1px solid black;
  outline: none;
  width: 100%;
  padding: 15px 10px;
  margin-top: 6px;
}

.input_box input::placeholder {
  color: #8b6d6d;
}

.Remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  cursor: pointer;
  color: #8b6d6d;
}

.Remember-forget a {
  color: #5fade0;
  text-decoration: none;
}

.Remember-forget label {
  display: flex;
  align-items: center;
}

.Remember-forget label input {
  margin-right: 3px;
}

.btn button {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 44px;
  margin-bottom: 37px;
  background-color: #4288f1;
  color: white;
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.dash_board {
  width: 20%;
  background-color: #ededed;
  box-shadow: 4px 0 10px -2px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: auto;
  height: 100vh;
  top: 0;
  bottom: 0;
}

.dashboard {
  position: sticky;
  top: 0;
  background-color: #ededed;
  z-index: 999;
}

.dash_board .img1 {
  display: flex;
  justify-content: center;
}

.dash_board .img1 img {
  padding: 20px 0;
  width: 150px;
}

.dash_board .dash1 {
  padding-top: 10px;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.dash_board .dash2 {
  padding: 10px;
}

.dash_board .dash2 .has-heading h4 {
  padding: 10px 0;
}

.dash_board .dash_1 .side_comp_cont {
  text-decoration: none;
  color: black;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
}

.dash_board .dash_1 .side_comp_cont .image img {
  width: 20px;
  height: 20px;
}

.dash_board .dash_1 span {
  padding-left: 15px;
}

.dash_board .dash_1 {
  list-style-type: none;
  cursor: pointer;
  border-radius: 4px;
}

.dash_board .dash_1:hover {
  background-color: #4288f1;
  opacity: 0.7;
  color: white;
}

.dash_board .dash_1:clicked {
  background-color: #4288f1;
  color: white;
}

hr {
  margin: 10px 0;
}

/* -----------------------overview css start------------------------  */
.overview-main {
  width: 100%;
}

/* .Home_icon {
  position: relative;
  top: 10px;
  left: 10px;
} */
.filter-icon {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
  font-weight: 400;
  cursor: pointer;
}

.overview-filter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px; */
  margin-top: 15px;
}

.overview-div1 {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  /* background-color: #4288f1; */
  color: rgb(37, 36, 36);
  font-weight: 600;
  padding-left: 10px;
  border-bottom: 2px solid #4b4a4a;
  font-size: 18px;
  cursor: pointer;
}

.overview-div2 {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  /* background-color: #4288f1; */
  color: rgb(51, 50, 50);
  font-weight: 600;
  padding-left: 10px;
  border-radius: 5px 5px 0 0;
  border-bottom: 3px solid #969494;
  font-size: 18px;
  cursor: pointer;
}

.Home_card {
  display: flex;
  gap: 15px;
}

.Home_content {
  background-color: #4288f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(146, 8, 8, 0.1);
  width: 25%;
  overflow: hidden;
  transition: transform 0.3s ease;
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.Home_content .line {
  color: #ffffff;
  font-weight: 100;
  opacity: 0.7;
}

.Home_content .line2 {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
}

.Home_content .line2 h4 {
  color: #ffffff;
  opacity: 0.7;
}

.Home_content .line2 span {
  opacity: 1 !important;
  color: white;
  font-size: 20px;
}

.Home_content .line_2 {
  display: flex;
  gap: 8px;
}

.overview_table {
  font-size: 10px;
  width: 100%;
  border-collapse: collapse;
}

.overview_table thead {
  position: sticky;
  top: 34.5px;
}

.overview_table th {
  background-color: #4288f1;
  color: white;
  padding: 0 10px;
  line-height: 40px;
  border: none;
}

.overview_table tr {
  line-height: 50px;
  text-align: center;
}

.overview_table td {
  padding: 0 8px;
  line-height: 45px;
}

.overview_table tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* -----------------------overview css end------------------------  */
/* ----------------------------------Navbar css started from here ------------------------------*/
.main {
  width: 80%;
  overflow-y: auto;
}

.main1 .logo {
  margin-left: 35px;
}

.main1 .logo_text {
  margin-left: 35px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.main1 .logo_text span {
  display: flex;
  align-items: center;
}

.main1 li {
  padding: 10px;
}

.main1 li i {
  font-size: 14px;
}

.main1 .navbaricon {
  margin-top: 10px;
  cursor: pointer;
}

.main1 .navbaricon > * {
  font-size: 22px;
}

.main1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  height: 75px;
}

.main1 ul {
  display: flex;
  list-style-type: none;
}

.main1 ul li {
  display: flex;
  margin-right: 14px;
}

.navbar_input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  border: none;
  outline: none;
  height: 35px;
  width: 140px;
  background-color: #ededed;
}

.main1 button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  outline: none;
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.main1 button:hover {
  background-color: #4288f1;
  color: white;
}

.main_container {
  padding: 30px 30px 0;
  background-color: #ffffff;
  /* height: 100%; */
}

.main_container_bottom {
  height: 50px;
}

.account-pannel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
}

.account-div1 {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  border-bottom: 2px solid #333333;
  font-size: 18px;
  cursor: pointer;
}

.account-div2 {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  border-bottom: 2px solid #333333;
  font-size: 18px;
  cursor: pointer;
}

.account-div2 a {
  width: 100%;
  color: black;
  text-decoration: none;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.account-div3 {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  border-bottom: 2px solid #333333;
  font-size: 18px;
  cursor: pointer;
}

.notification-model {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 6px;
  height: 100%;
}

.notification1 {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  height: 30px;
  padding: 8px;
  border-bottom: 0.5px solid #ddd;
}

.notification1 button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 6px;
  font-weight: 600;
}

.notification2 {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  padding-top: 6px;
}

.notification2::-webkit-scrollbar {
  display: none;
}

.notification3 {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.notification3 li {
  padding: 6px;
  border-left: 2px solid #4432e7;
  /* Border between items */
  margin-bottom: 10px;
}

.notification3 p {
  margin: 0;
}

/* -------------------------------------- navbar css started from here -------------------------- */

.home_card2 {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}

.Home_content2 {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(146, 8, 8, 0.1);
  border: 1px solid black;
  width: 100%;
  overflow: hidden;
  transition: transform 0.3s ease;
  padding: 8px;
}

.Home_content2 .line2 {
  display: flex;
  gap: 10px;
  margin-top: 6px;
}

.Home_line3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}

.Home_content2 .line3 {
  display: flex;
  align-items: center;
  gap: 5px;
  color: grey;
}

.Home_content2 .line3 span {
  font-weight: 700;
  color: black;
}

.Home_line3 .Home_span {
  color: grey;
}

.Home_line3 .Home_span span {
  font-weight: 700;
  color: black;
}

.home_card22 {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.Tag-Table .card {
  width: 25%;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid black;
  text-align: center;
  overflow: auto;
  position: relative;
}

.Tag-Table .card::-webkit-scrollbar {
  display: none;
}

.Tag-Table .card h3 {
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  padding: 8px;
}

.Tag-Table .card .card_shipment {
  padding: 8px;
  padding-top: 0;
}

.card-content1 {
  padding: 5px;
}

.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
}

.progress-bar {
  width: 70%;
  height: 100%;
  background-color: #4288f1;
}

.progress-label {
  font-size: 14px;
  color: #333333;
}

.progress_load {
  display: flex;
  justify-content: space-between;
}

.Graph-Loader {
  width: 100%;
  height: 283px;
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.Loader-Tag {
  display: flex;
  width: 100%;
  height: 270px;
  gap: 10px;
}

.graph {
  max-width: 45%;
  width: 100%;
  height: 270px;
  border: 1px solid black;
  border-radius: 8px;
}

.graph1 {
  width: 50%;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
}

.graph1 .graph1_tag {
  display: flex;
  flex-direction: column;
}

.graph1 .graph11_tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph1 .color1 {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #f01919;
}

.graph1 .color2 {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #ff8675;
}

.graph1 .color3 {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: #f39b30;
}

.graph1 .color4 {
  height: 10px;
  width: 10px;
  border-radius: 2px;

  background-color: #ffd096;
}

.loading-indicator:before {
  content: "";
  background: #00000080;
  /* position: fixed; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* z-index: 1000; */
}

/* @keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */
.graph1_tag1 {
  margin-bottom: 20px;
}

.graph_tag2 {
  display: flex;
  gap: 5px;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.graph_tag2 .graph_tag21 {
  display: flex;
  gap: 4px;
  background-color: #ededed;
  padding: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.graph_tag3 p {
  font-size: 12px;
  width: -webkit-fill-available;
}

.Tag-Table {
  width: 100%;
  height: 283px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  max-width: 100%;
}

.Tag_tag {
  width: 100%;
  display: flex;
  height: 270px;
  gap: 10px;
}

.home_table {
  padding-top: 20px;
}

.tag_table1 {
  width: 50%;
}

.table1 {
  height: 100%;
  padding-top: 0 !important;
  padding: 6px;
  width: 62%;
  border-radius: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid black;
  margin-top: 0px;
}

.table1::-webkit-scrollbar {
  display: none;
}

.table1 h4 {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 8px 5px;
}

.useroverview_table th {
  line-height: 15px;
}

.useroverview_table td {
  line-height: 15px !important;
}

.useroverview_modal {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
}

.useroverview_modal span {
  cursor: pointer;
}

.user_overveiwmodal1 {
  width: 75%;
  margin-top: 15px;
  border-bottom: 1px solid rgb(168, 163, 163);
  padding-bottom: 10px;
}

.user_overveiwmodal1 .bid_submodalA {
  display: flex;
  margin-top: 10px;
}

.bid_submodalA .bid_submodala {
  width: 10%;
  color: gray;
  font-weight: 200;
  font-size: 12px;
}

.bid_submodalA .bid_submodalb {
  font-weight: 200;
  font-size: 12px;
}

.useroverview_modalimg {
  width: 25%;
  display: flex;
  align-items: center;
}

.useroverview_modaling img {
  height: 140px;
  width: 140px;
}

.mainmodal_adhar {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.main_adhar {
  display: flex;
  gap: 80px;
  margin-top: 15px;
}

.modaling_adhaar {
  width: 32%;
}

.modaling_adhaar .modal_adhaar {
  font-size: 14px;
  display: flex;
  margin: 10px;
  gap: 8px;
}

.main_userview {
  display: flex;
}

.tag_tag1 {
  width: 50%;
  border: 1px solid black;
}

.color_report {
  display: flex;
  align-items: center;
  gap: 2px;
}

.graph_loader {
  display: flex;
  flex-direction: column;
}

.color_content {
  display: flex;
}

.Report {
  width: 100% !important;
}

.Report .home_table {
  padding-top: 20px;
}

.Report .table1 {
  width: 100% !important;
  overflow-x: auto;
  margin-top: 20px;
}

.Report .content_table {
  width: 100% !important;
  border-collapse: collapse;
  margin-top: 10px;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.Report .content_table th {
  background-color: #4288f1;
  color: white;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  padding: 7px 20px;
  font-size: 14px;
}

.report_heading {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px;
}

.Report .content_table td {
  padding: 10px 20px;
}

.Report .content_table tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.Report .table2_fullbtn {
  display: flex;
  gap: 15px;
}

.table2_fullbtn button {
  border-radius: 6px;
  outline: none;
  border: 1px solid black;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

#select_date {
  background-color: #ededed;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 4px 8px;
}

.dropdown1 select {
  border: none;
  outline: none;
  padding: 4px 8px;
  cursor: pointer;
  background-color: #ededed;
  border-radius: 4px;
}

.report-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.report_main_sub {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  text-align: center;
}

.report_main_sub1 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* justify-content: space-between; */
}

.report-button {
  border: 1px solid red;
  border-radius: 5px;
  width: 191px;
  height: 40px;
  cursor: pointer;
}

.report_cancel {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.report_cancel1 {
  border: 1px solid red;
  border-radius: 5px;
  width: 138px;
  height: 40px;
  cursor: pointer;
}

.report_cancel2 {
  border-radius: 5px;
  border: none;
  background-color: #4288f1;
  color: white;
  width: 138px;
  height: 40px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  color: black;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.Filters {
  display: flex;
  align-items: center;
  gap: 28px;
  border: none;
  outline: none;
}

.Filters .dropdown1 {
  display: flex;
  align-items: center;
}

.Filters1 {
  margin-top: 15px;
  border: none;
  outline: none;
}

.Report_Button {
  /* padding: 20px; */
  margin: 20px 20px 20px 0;
  display: flex;
  gap: 16px;
  /* width: 40px; */
}

.Report_Button .Report_Butt {
  color: #ffffff;
  background-color: #4288f1;
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  cursor: pointer;
}

/* .Report_butt1{
  margin: 15px;
  display: flex;
  gap: 20px;
 
} */
.Main_reportloader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.graph_loader .Report_Rupees {
  display: flex;
  align-items: center;
  gap: 1px;
  font-size: 16px;
  font-weight: 600;
}

.main_report {
  display: flex;
  padding: 10px;
  gap: 15px;
}

.mainreport_butt {
  border: none;
  outline: none;
  padding: 0px 28px;
  border-radius: 8px;
}

.report_butt {
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: black;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.report_butt.active {
  font-weight: bold;
  border-bottom: 1px solid black;
}

/* .report_butt {
  background-color: white !important;
  border: none;
  outline: none;
  padding: 0px 10px;
  border-radius: 6px;
  cursor: pointer;
} */
.main_butt1 {
  padding: 12px 36px;
  background-color: white;
  outline: none !important;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
}

.Report_loader:before {
  content: "";
  background: #00000080;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* .graph1_tag1{
  margin-bottom: 20px;
} */
.graph_loader {
  display: flex;
  gap: 5px;
  font-size: smaller;
  justify-content: center;
  align-items: center;
}

/* .graph_tag3{
  display: flex;
  gap: 5px;
  font-size: smaller;
  justify-content: center;
    align-items: center;
} */
.graph_loader .color1 {
  height: 15px;
  width: 15px;
  background-color: #f01919;
  border-radius: 50%;
}

.graph_loader .color2 {
  height: 15px;
  width: 15px;
  background-color: #ff8675;
  border-radius: 50%;
}

.graph_loader .color3 {
  height: 15px;
  width: 15px;
  background-color: #f39b30;
  border-radius: 50%;
}

.container1 {
  display: flex;
  align-items: center;
}

.container1 .line {
  width: 1px;
  height: 60px;
  background-color: black;
}

.main_butt {
  border: none;
  outline: none;
  padding: 4px 8px;
  border-radius: 3px;
  cursor: pointer;
}

.Report_butt2 {
  display: flex;
  gap: 20px;
}

.payment_input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  margin-top: 22px;
  width: 250px;
  border: 1px solid grey;
  border-radius: 6px;
  background-color: #ededed;
  cursor: pointer;
}

.payment_input input {
  background-color: #ededed;
  padding-right: 10px;
  border: none;
  outline: none;
  width: 100%;
}

.payment_input i {
  font-size: 20px;
  color: #aaa;
}

.payment_img {
  display: flex;
  width: 60%;
  background-color: #ededed;
  padding: 15px;
  gap: 10px;
  margin-bottom: 20px;
}

.payment_data {
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: grey;
}

.payment_data .payfont {
  color: black;
  font-size: 16px;
}

.payment_data .payfont1 {
  background-color: #f67474;
  border-radius: 5px;
  padding: 3px 7px;
}

.content_inputA {
  display: flex;
  gap: 50px;
}

.content_input1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.content_input1 input {
  width: 200px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid grey;
  outline: none;
  padding: 10px 6px;
}

.content_inputB {
  display: flex;
  gap: 50px;
}

.content_input2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.content_input2 input {
  width: 200px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid grey;
  outline: none;
  padding: 10px 6px;
}

.content_input2a {
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 16px;
}

.content_input2a select {
  width: 200px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid grey;
  outline: none;
  padding: 10px 6px;
  cursor: pointer;
}

select {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

.payment_button {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.payment_button .paybutt1 {
  padding: 8px 14px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #4288f1;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 700;
}

.payment_button .paybutt2 {
  padding: 8px 14px;
  font-size: 16px;
  border: 1px solid red;
  border-radius: 6px;
  outline: none;
  color: red;
  background-color: white;
  font-weight: 700;
  cursor: pointer;
}

.Usercreation_butt1 {
  display: flex;
  gap: 20px;
  padding: 10px 0px;
  /* border-bottom: 2px solid #4432e7; */
}

.report_butt1 {
  border: none;
  font-size: larger;
  font-weight: 500;
  background-color: transparent;
  padding: 8px 0px;
  width: 10rem;
  /* border-bottom: 3px solid #4288f1; */
}

.report_butt2 {
  border: none;
  font-size: larger;
  font-weight: 500;
  background-color: transparent;
  padding: 8px 0px;
  width: 10rem;
}

.UserCreation_input {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;

  margin-top: 20px;
  padding-left: 20px;
  width: 250px;
  font-size: 16px;
  height: 42px;
  color: white;
  font-weight: 500;
  background-color: #4288f1;
  border: none;
  outline: none;
  border-radius: 8px;
}

.UserCreation_input .input-with-icon input {
  padding-left: 40px;
  width: 275px;
  font-size: 16px;
  height: 32px;
  background-color: #4288f1;
  border: none;
  outline: none;
  border-radius: 5px;
}

.UserCreation_input .input-with-icon input::placeholder {
  color: white;
}

.main-client {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.subclient1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subclient1 strong {
  font-size: x-large;
  cursor: pointer;
}

.subclient2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: large;
}

.subclient2 h4 {
  font-weight: 600;
  font-size: 18px;
}

.subclient21 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subclient21 input {
  padding: 12px;
  border-radius: 8px;
  outline: none;
  border: 1px solid black;
}
.subclient21 select {
  padding: 12px;
  border-radius: 8px;
  outline: none;
  border: 1px solid black;
}

.subclient21 button {
  width: max-content;
  border-radius: 4px;
  background-color: #17a2b8;
  border: none;
  outline: none;
  padding: 14px 20px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.subclient22 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 15px;
}

.subclient3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: large;
}

.subclient3 p {
  font-size: small;
}

.subclient31 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subclient31 input {
  border-radius: 8px;
  padding: 12px;
  outline: none;
  border: 1px solid black;
}

.subclient3 input[type="file"] {
  display: none;
}

.subclient3 .custom-file-upload {
  width: max-content;
  height: fit-content;
  border-radius: 4px;
  background-color: #17a2b8;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.subclient4 {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.subclient4 button {
  width: max-content;
  border-radius: 4px;
  background-color: #17a2b8;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.UserCreation_table1 {
  margin-top: 30px;
}

.UserCreation_Button {
  display: flex;
  gap: 30px;
  flex-direction: row;
}

.UserCreation_Button Button {
  border-radius: 8px;
  outline: none;
  border: 1px solid black;
  padding: 3px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 35px;
}

.user_creation_btn {
  width: 120px !important;
}

.Employemanage_input {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;

  margin-top: 20px;
  padding-left: 20px;
  width: 250px;
  font-size: 16px;
  height: 42px;
  color: white;
  font-weight: 500;
  background-color: #4288f1;
  border: none;
  outline: none;
  border-radius: 8px;
}

/* .Employeemanage_input .input-with-icon button {
  width: 275px;
  font-size: 16px;
  height: 32px;
  background-color: #4288f1;
  color: white;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
} */

/* .Employeemanage_input .input-with-icon input::placeholder {
  color: white;
} */

.sub_employe2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  gap: 12px;
}

.sub_employe2 div img {
  border-radius: 8px;
  object-fit: cover;
}

.sub_imp_3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.sub_employe1 strong {
  font-size: larger;
}

.sub_employe_img {
  border-radius: 10px;
}

.sub_employe2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
}

.sub_employe2 button {
  text-wrap: nowrap;

  /* width: max-content;
  border-radius: 4px;
  background-color: #17a2b8;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  padding: 8px 8px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: large; */
}

.sub_imp_3 span {
  font-size: 16px;
}

.sub_imp_3 img {
  margin-top: 10px;
  width: 190px;
  height: 130px;
}

.employee_table {
  width: 100%;
  margin-top: 30px;
}

.employee_table .thead {
  position: sticky;
  top: 0;
}

.Bidsubmission {
  display: flex;
  gap: 15px;
  margin-top: 12px;
}

.Bidsub_view1 {
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #4288f1;
  opacity: 0.7;
  color: white;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Bidsub_view1 i {
  margin-right: 5px;
}

.Bidsub_view2 {
  border-radius: 6px;
  border: none;
  outline: none;
  background-color: #e6e9e9;
  color: red;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.Bidsub_view2 i {
  margin-right: 5px;
}

/* bidsubmission modal START*/
.Bid_Submission_modal .bid_submodal1 {
  display: flex;
  justify-content: space-between;
}

.Bid_Submission_modal .bid_submodal1 h4 {
  color: grey;
}

.Bid_Submission_modal .bid_submodal1 span {
  font-weight: 900;
  font-size: x-large;
  cursor: pointer;
}

.bid_submodal2 {
  margin-top: 15px;
}

.bid_submodal2 .bid_submodalA {
  display: flex;
  margin-top: 10px;
}

.bid_submodalA .bid_submodala {
  width: 30%;
  color: gray;
  font-weight: 600;
}

.bid_submodalA .bid_submodalb {
  font-weight: 600;
}

.modalbuttn_close {
  display: flex;
  gap: 15px;
  margin-top: 12px;
}

.modalbuttn_close .modal_buttn button {
  border-radius: 6px;
  outline: none;
  border: 1px solid grey;
  padding: 6px 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.modalbuttn_close .modal_close button {
  border-radius: 6px;
  border: none;
  outline: none;
  color: Red;
  background-color: none;
  padding: 6px 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}

.bidsubmodal_table {
  overflow: auto;
  height: 200px;
  margin-top: 20px;
  scrollbar-width: none;
}

.bidsubmodal_table td {
  padding: 4px 16px !important;
}

.bidsubmodal_table th {
  padding: 4px 16px !important;
}

/* bidSub modal END */
.main_Bid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.Bid_Review {
  text-align: center;
  display: flex;
  gap: 15px;
}

.main_Bid button {
  margin-right: 70px;
  margin-top: 17px;
  padding: 7px 20px;
  background-color: #4288f1;
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
}

.BidReview_input1 h3 {
  padding-right: 100px;
  margin-bottom: 5px;
}

.BidReview_input1 select {
  width: 250px;
  height: 30px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
}

.BidReview_input2 .input-with-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.BidReview_input2 .input-with-icon i {
  position: absolute;
  margin-left: 10px;
  font-size: 16px;
  color: #999;
}

.BidReview_input2 .input-with-icon input {
  padding: 6px 6px 6px 30px;
  width: 330px;
  font-size: 16px;
  border-radius: 6px;
  background-color: #ededed;
  border: none;
  outline: none;
}

.BIDOR {
  display: flex;
  flex-direction: column;
}

.Customer_card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(146, 8, 8, 0.1);
  border: 1px solid #4288f1;
  width: 32%;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.img_content img {
  width: 25%;
  height: 80px;
  object-fit: cover;
}

.customer_content {
  width: 71%;
}

.customer_content .line {
  margin-top: 10px;
  font-size: 12px;
  color: grey;
}

.customer_content .line span {
  color: black;
  margin-right: 5px;
}

.card-content {
  padding: 20px;
  padding-bottom: 0px;
}

.card-title {
  font-size: 18px;
  margin-top: 10px;
}

.card-description {
  font-size: 13px;
  color: #555;
  margin-bottom: 20px;
}

.Customer_card .img_content {
  display: flex;
  gap: 10px;
}

.Main_feedback {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.Customerfeed_Button1 {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.Customerfeed_Button1 button {
  padding: 8px 10px;
  background-color: white;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
}

.Customerfeed_Button1 button :focus {
  text-decoration: underline;
}

/* =============Setting Page CSS Start */

.setting_page {
  border: 1px solid #dedddd;
  padding: 12px;
  border-radius: 6px;
}

.setting_page_body .head {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.setting_page_body .head span {
  display: flex;
  align-items: center;
}

.setting_page_body .head span > * {
  font-size: 20px;
  font-weight: 500;
}

.setting_page_body .head h4 {
  font-weight: 500;
}

.basic_information {
  margin-top: 30px;
}

.basic_information .name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.basic_information .name label {
  width: 30%;
  font-weight: 700;
}

.basic_information .name input {
  padding: 12px;
  width: -webkit-fill-available;
  border: 1px solid black;
  outline: none;
  border-radius: 6px;
}

.basic_information .button {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.basic_information .button button {
  padding: 8px 16px;
  border: 2px solid black;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
}

.basic_information .name .requirements {
  width: 23%;
}

.basic_information .name ul {
  margin-left: 20px;
}

.basic_information .delete_button {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.basic_information .delete_button button {
  padding: 8px 16px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  background-color: green;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.delete_account_note {
  display: flex;
  gap: 6px;
  margin-bottom: 30px;
}

.delete_account_note span {
  font-weight: 700;
}

.delete_account_caution {
  margin-bottom: 30px;
}

.delete_account_caution p {
  font-weight: 700;
}

.delete_account_confirm {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification_permission {
  display: flex;
  justify-content: center;
}

.notification_permission span {
  font-weight: 700;
}

.notification_activity {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.notification_activity_title {
  width: 60%;
}

.preferences_head {
  display: flex;
  align-items: center;
  padding-left: 50px;
}

.preferences_body {
  height: 158px;
  overflow: auto;
}

.basic_information .accordion {
  margin-bottom: 30px;
}

.preferences_body::-webkit-scrollbar {
  display: none;
}

.preferences_body table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.preferences_body table th,
td {
  border: 1px solid grey;
}

.preferences_body table th,
td {
  text-align: center;
  padding: 10px;
  text-wrap: wrap;
}

.preferences_body table td input {
  cursor: pointer;
}

.preference_new_role button {
  padding: 8px 16px;
  border: 1px solid black;
  outline: none;
  border-radius: 6px;
  font-weight: 500;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.preference-main_head {
  display: flex;
  justify-content: end;
}

.preference-main_head span {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

.preference-main .head {
  padding-top: 20px;
  position: relative;
}

.preference-main .next_btn {
  padding: 8px 16px;
  border: 2px solid black;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.main_roleResponsibility {
  height: 260px;
  overflow: auto;
  margin-top: 10px;
  margin-bottom: 20px;
}

.main_roleResponsibility::-webkit-scrollbar {
  display: none;
}

.preference-main .role_input {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
}

.preference-main .role_input label {
  font-size: 14px;
}

.preference-main .role_input input {
  padding: 12px;
  width: 40%;
  outline: none;
  border: 1px solid black;
  border-radius: 6px;
}

.preference-main .role_button {
  display: flex;
  justify-content: end;
  position: relative;
  right: 20%;
}

.preference-main .role_button button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px;
  background-color: red;
  color: white;
  border: none;
  outline: none;
  border-radius: 6px;
  margin-bottom: 20px;
}

.Role_Responsiblity_modal {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-evenly;
}

.Role_Responsiblity_modal .Role_modal .Role_modal1 {
  font-size: 16px;
  font-weight: 500;
}

.Role_Responsiblity_modal .Role_modal .Role_modal2 {
  font-size: 12px;
  color: gray;
  margin-top: 3px;
}

.Role_Responsiblity_modal .Role_modal3 input {
  width: 22px;
  height: 22px;
}

.modal_button {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.modal_button button {
  padding: 8px 16px;
  border: 2px solid black;
  outline: none;
  border-radius: 6px;
  font-weight: 700;
  background-color: transparent;
  cursor: pointer;
}

/* =============Setting Page CSS End */

.resend-button {
  display: flex;
  justify-content: end;
  align-self: flex-end;
  margin-top: 8px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  background-color: #4288f1;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* --------------Pagination  Component Css Start----------------- */

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pagination button {
  border: none;
  outline: none;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #17a2b8;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

/* --------------Pagination  Component Css End----------------- */
.upload_file {
  display: flex;
  flex-direction: column;
}

.upload_file input[type="file"] {
  display: none;
}

.upload_file .custom-file-upload {
  width: max-content;
  border-radius: 4px;
  background-color: #17a2b8;
  border: none;
  outline: none;
  padding: 14px 20px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.upload_file .image_preview img {
  border-radius: 0;
  width: 100px;
  height: 70px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.view_mod {
  display: flex;
  gap: 20px;
  margin-top: 16px;
}

.Approve {
  display: flex;
  color: white;
  gap: 10px;
  padding: 8px;
  border: none;
  background-color: rgb(22, 172, 22);
  border-radius: 8px;
  font-size: 16px;
  padding: 8px;
}

.Deny {
  display: flex;
  color: white;
  gap: 10px;
  padding: 8px;
  border: none;
  background-color: rgb(241, 28, 13);
  border-radius: 8px;
  font-size: 16px;
}

/* ===================toggle sidebar css below start ====================*/
@media (max-width: 768px) {
  .dash_board {
    display: none;
    position: fixed;
    width: 100%;
    max-width: 250px;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
  }

  .dash_board.visible {
    display: block;
  }

  .main {
    width: 100%;
  }

  .sidebar_show_btn {
    display: inline-block;
    cursor: pointer;
    font-size: 1.5em;
    margin-left: 10px;
  }

  .sidebar_hide_btn {
    display: flex;
    justify-content: end;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5em;
  }
}

@media (min-width: 769px) {
  .dash_board {
    display: block;
  }

  .sidebar_show_btn {
    display: none;
  }

  .sidebar_hide_btn {
    display: none;
  }
}

/* ===================toggle sidebar css below end ====================*/
.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper form {
  width: 400px;
  padding: 50px;
  padding-top: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(249 246 249);
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.wrapper form h1 {
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.wrapper .input_box {
  padding-top: 14px;
  width: 100%;
  position: relative;
}

.wrapper .input_box input {
  border: none;
  outline: none;
  color: black;
  border-radius: 8px;
  width: 100%;
  padding: 15px 10px;
}

.wrapper .input_box input::placeholder {
  color: #8b6d6d;
}

.wrapper .input_box i {
  position: absolute;
  margin-left: -27px;
  margin-bottom: -20px;
  margin-top: 10px;
}

.wrapper .Remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  cursor: pointer;
  color: #8b6d6d;
}

.wrapper .Remember-forget a {
  color: #5fade0;
  text-decoration: none;
}

.wrapper .Remember-forget label input {
  margin-right: 3px;
}

.wrapper .btn button {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 44px;
  margin-bottom: 37px;
  background-color: #25ad0f;
  color: white;
}

.wrapper .butn {
  padding-top: 40px;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile form {
  width: 400px;
  padding: 50px;
  padding-top: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(249 246 249);
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.profile form h1 {
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.profile .input_box {
  padding-top: 14px;
  width: 100%;
  position: relative;
}

.profile .input_box input {
  border: none;
  outline: none;
  color: black;
  border-radius: 8px;
  width: 100%;
  padding: 15px 10px;
}

.profile .input_box input::placeholder {
  color: #8b6d6d;
}

.profile .input_box i {
  position: absolute;
  margin-left: -27px;
  margin-bottom: -20px;
  margin-top: 10px;
}

.profile .Remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;
  cursor: pointer;
  color: #8b6d6d;
}

.profile .Remember-forget a {
  color: #5fade0;
  text-decoration: none;
}

.profile .Remember-forget label input {
  margin-right: 3px;
}

.profile .btn button {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 44px;
  margin-bottom: 37px;
  background-color: #25ad0f;
  color: white;
}

.profile .butn {
  padding-top: 40px;
}

.profile .profile_img {
  position: relative;
  text-align: center;
}

.profile .profile_img .edit_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: 38%;
  background-color: red;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.profile .profile_img .edit_icon > * {
  color: white;
}

.order-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
}
.order_info {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 25px;
}

.order_info .orders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 35%;
  height: 25vh;
  border-radius: 4px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.order_info .orders a {
  text-decoration: none;
  display: flex;
  padding: 10px;
  width: 100%;
}

.order_info .icon {
  display: flex;
  height: 50%;
  width: 25%;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: #71d255;
  padding: 10px;
  border-radius: 4px;
}

.order_info .icon > * {
  width: 42px;
  height: 42px;
  color: white;
}

.order_info .content {
  gap: 12px;
  width: 70%;
  margin-left: 13px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  color: #ffc107;
}
.order_info .content {
  font-size: 21px;
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 6px 0 20px;
}

.header p {
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  gap: 9px;
  color: grey;
  font-weight: 600;
  font-size: 16px;
}

.header span {
  font-weight: 400;
  font-size: small;
}

.header i {
  font-size: 20px;
}
